/* CSS variables for dev */
/* 
/* Font face definitions */
@font-face {
  font-family: "CircularXXWeb";
  src: url("/fonts/CircularXXWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "CircularXXWeb";
  src: url("/fonts/CircularXXWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "CircularXXWeb";
  src: url("/fonts/CircularXXWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/* Base CSS variables */
:root {
  /* Font family */
  --font-family: "CircularXXWeb", system-ui, sans-serif;

  /* Font weights */
  --regular-font-weight: 400;
  --medium-font-weight: 500;
  --bold-font-weight: 700;

  /* Other variables... */
  --toastify-toast-min-height: 36px;
  --toastify-color-light: #fff;
  --toastify-toast-width: auto;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* Background gradient that takes exactly 1 screen height, and is white underneath. */
.bg-gradient {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50vh,
      rgba(255, 255, 255, 1) 100vh
    ),
    url("/images/background.webp") lightgray 50% / cover no-repeat;
}

/* Background gradient that takes at most 1 screen height, but will shrink down to the height of
the element if it is less than 100vh tall. */
.bg-gradient-stretch {
  background: linear-gradient(180deg, #f7fffd 0%, #ffffff 100%);

  background-size: 100% min(100vh, 100%);
  background-repeat: no-repeat;
  background-position: top;
  background-color: #ffffff; /* fallback */
}

.bg-image {
  background: url("/images/background.webp") lightgray 50% / cover no-repeat;
  @apply bg-fixed bg-no-repeat bg-cover;
}

body {
  @apply text-fg-base;
  font-family: var(--font-family);
  font-weight: var(--regular-font-weight);
}
@layer utilities {
  .h1-base {
    font-size: 1.75rem;
    /* 28px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .h2-md {
    font-size: 1.375rem;
    /* 22px */
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .h2-base {
    font-size: 1.375rem;
    /* 22px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .h3-base {
    font-size: 1.25rem;
    /* 20px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .tiny-normal {
    font-size: 0.8125rem;
    /* 13px */
    font-family: var(--font-family);
    font-weight: var(--regular-font-weight);
  }

  .font-md {
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .tiny-md {
    font-size: 0.8125rem;
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .tiny-bold {
    font-size: 0.8125rem;
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .sm-normal {
    font-size: 0.9375rem;
    font-family: var(--font-family);
    font-weight: var(--regular-font-weight);
  }

  .sm-md {
    font-size: 0.9375rem;
    /* 15px */
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .sm-bold {
    font-size: 0.9375rem;
    /* 15px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .base-normal {
    font-size: 1rem;
    font-family: var(--font-family);
    font-weight: var(--regular-font-weight);
  }

  .base-md {
    font-size: 1rem;
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .base-bold {
    font-size: 1rem;
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }

  .lg-normal {
    font-size: 1.125rem;
    /* 18px */
    font-family: var(--font-family);
    font-weight: var(--regular-font-weight);
  }

  .lg-md {
    font-size: 1.125rem;
    /* 18px */
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .lg-bold {
    font-size: 1.125rem;
    /* 18px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }
}

body.sidebar-expanded {
  --sidebar-width: 300px;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: margin-left 0.3s;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sidebar-width);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  background-color: white;
  z-index: 50;
}

.to-white-y-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.vertical-dark-gradient {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.25) 100%
    ),
    var(--base-background);
}

.bottom-fade-out {
  --fade-distance: 80px; /* Default value for the fade distance. */
  /* Example of changing fade-distance <div class="fade" style="--fade-distance: 60px;">Content here...</div> */

  --mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) calc(100% - var(--fade-distance)),
    /* Content not masked until <fade-distance> from the bottom. */
      rgba(0, 0, 0, 0) 100% /* Content fully masked at the bottom. */
  );

  mask-image: var(--mask);
  -webkit-mask-image: var(--mask);
}

.research-paper-title {
  font-size: 1.125rem;
  /* 18px */
  font-family: var(--font-family);
  font-weight: var(--medium-font-weight);
}

.sparkle-box-full-border-gradient {
  background: linear-gradient(
        to right,
        #f2f7f900 0%,
        #f2f7f900 4%,
        #f2f7f9 100%
      )
      padding-box,
    linear-gradient(to bottom, #f0fcf8, #e8f8ff 100%) padding-box,
    linear-gradient(
        to right,
        #57b8ff 0%,
        #86e7cd 12%,
        var(--border-base, #dfeaee) 50%,
        var(--border-base, #dfeaee) 100%
      )
      border-box;
  border: 1px solid transparent;
  @apply rounded-r-xl;
}

.sparkle-box-full-border-gradient:hover {
  background: linear-gradient(
        to right,
        #dfeaee00 0%,
        #dfeaee00 4%,
        #dfeaee 100%
      )
      padding-box,
    padding-box, linear-gradient(to bottom, #f0fcf8, #e8f8ff 100%) padding-box,
    linear-gradient(
        to right,
        #57b8ff 0%,
        #86e7cd 12%,
        var(--border-base, #dfeaee) 50%,
        var(--border-base, #dfeaee) 100%
      )
      border-box;
  border: 1px solid transparent;
}

.sparkle-box {
  /* Leave space for the border */
  position: relative;
  padding-left: 2px;

  background: linear-gradient(
      to right,
      #f2f7f900 0%,
      #f2f7f900 4%,
      #f2f7f9 100%
    ),
    linear-gradient(to bottom, #f0fcf8, #e8f8ff 100%);

  @apply rounded-r-xl;
}

/* CSS Doesn't support graident borders, so fake it by positioning a 2px pseudo element with bg to the left */
.sparkle-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3px; /* Width of the "border" */
  background: linear-gradient(to bottom, #86e7cd, #57b8ff);
}

.share-btn-item {
  font-size: 0.8125rem;
  font-family: var(--font-family);
  font-weight: var(--medium-font-weight);
  @apply flex items-center w-full gap-3 p-4 border-b text-fg-base sm:p-2 border-b-border-base sm:border-b-0 hover:bg-bgr-faint;
}

.co-bottom-shadow {
  box-shadow: 0px 6px 12px 0px rgba(53, 70, 85, 0.04);
}

.ai-card-paywall {
  background: linear-gradient(
      to right,
      rgba(242, 247, 249, 0) 0%,
      rgba(242, 247, 249, 0) 4%,
      rgba(242, 247, 249, 0.6) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(240, 252, 248, 0.6),
      rgba(232, 248, 255, 0.6) 100%
    );
  backdrop-filter: blur(8px);
  @apply rounded-br-2xl border-t-1 border-t-border-emphasis p-4 ml-[3px];
}

.ai-card-paywall-with-border {
  background: linear-gradient(
      to right,
      rgba(242, 247, 249, 0) 0%,
      rgba(242, 247, 249, 0) 4%,
      rgba(242, 247, 249, 0.6) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(240, 252, 248, 0.6),
      rgba(232, 248, 255, 0.6) 100%
    );
  backdrop-filter: blur(8px);
  @apply p-4 rounded-br-2xl rounded-tr-2xl;
}

.ai-card-paywall-with-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3px;
  z-index: -1;
  background: linear-gradient(to bottom, #86e7cd, #57b8ff);
}

.share-btn-item.hide {
  display: none;
}

.question-page-header {
  @apply h2-base;
}

@media (min-width: 898px) {
  body {
    --sidebar-width: 80px;
  }

  .main-content {
    padding-left: var(--sidebar-width);
  }

  .question-page-header {
    @apply h1-base;
  }
}

.tippy-box .tippy-content {
  @apply p-0 rounded-xl;
}

.loading-dot {
  opacity: 0;
  animation: fadeInOut 1s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes emphasis {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate-emphasis {
  animation: emphasis ease 0.75s;
}

@keyframes collapse {
  0% {
    max-width: 200px;
    opacity: 1;
  }
  100% {
    max-width: 0;
    opacity: 0;
    display: none;
  }
}

.animate-collapse {
  animation: collapse 300ms ease forwards;
  @apply whitespace-nowrap;
}

/* Applying stagger-animation to an element gives its direct children offset animation delays causing a 'wave' effect in the pulses. The delay is applied to any nested element withhin the children that have the animate-pulse class applied. See e.g. the RelatedSearches component.  */
.stagger-animation > *:nth-child(8n + 1) .animate-pulse,
.stagger-animation > *:nth-child(8n + 1).animate-pulse {
  animation-delay: -0s;
}

.stagger-animation > *:nth-child(8n + 2) .animate-pulse,
.stagger-animation > *:nth-child(8n + 2).animate-pulse {
  animation-delay: -1.75s;
}

.stagger-animation > *:nth-child(8n + 3) .animate-pulse,
.stagger-animation > *:nth-child(8n + 3).animate-pulse {
  animation-delay: -1.5s;
}

.stagger-animation > *:nth-child(8n + 4) .animate-pulse,
.stagger-animation > *:nth-child(8n + 4).animate-pulse {
  animation-delay: -1.25s;
}

.stagger-animation > *:nth-child(8n + 5) .animate-pulse,
.stagger-animation > *:nth-child(8n + 5).animate-pulse {
  animation-delay: -1s;
}

.stagger-animation > *:nth-child(8n + 6) .animate-pulse,
.stagger-animation > *:nth-child(8n + 6).animate-pulse {
  animation-delay: -0.75s;
}

.stagger-animation > *:nth-child(8n + 7) .animate-pulse,
.stagger-animation > *:nth-child(8n + 7).animate-pulse {
  animation-delay: -0.5s;
}

.stagger-animation > *:nth-child(8n + 0) .animate-pulse,
.stagger-animation > *:nth-child(8n + 0).animate-pulse {
  animation-delay: -0.25s;
}

/* Applying stagger-animation to an element gives its direct children offset animation delays causing a 'wave' effect in the pulses. The delay is applied to any nested element withhin the children that have the animate-pulse class applied. See e.g. the RelatedSearches component.  */
.stagger-animation > *:nth-child(8n + 1) .animate-pulse,
.stagger-animation > *:nth-child(8n + 1).animate-pulse {
  animation-delay: -0s;
}

.stagger-animation > *:nth-child(8n + 2) .animate-pulse,
.stagger-animation > *:nth-child(8n + 2).animate-pulse {
  animation-delay: -1.75s;
}

.stagger-animation > *:nth-child(8n + 3) .animate-pulse,
.stagger-animation > *:nth-child(8n + 3).animate-pulse {
  animation-delay: -1.5s;
}

.stagger-animation > *:nth-child(8n + 4) .animate-pulse,
.stagger-animation > *:nth-child(8n + 4).animate-pulse {
  animation-delay: -1.25s;
}

.stagger-animation > *:nth-child(8n + 5) .animate-pulse,
.stagger-animation > *:nth-child(8n + 5).animate-pulse {
  animation-delay: -1s;
}

.stagger-animation > *:nth-child(8n + 6) .animate-pulse,
.stagger-animation > *:nth-child(8n + 6).animate-pulse {
  animation-delay: -0.75s;
}

.stagger-animation > *:nth-child(8n + 7) .animate-pulse,
.stagger-animation > *:nth-child(8n + 7).animate-pulse {
  animation-delay: -0.5s;
}

.stagger-animation > *:nth-child(8n + 0) .animate-pulse,
.stagger-animation > *:nth-child(8n + 0).animate-pulse {
  animation-delay: -0.25s;
}

.shadow-card {
  box-shadow: 3px 15px 20px rgb(210 222 241 / 26%);
}

.text-gradient {
  background: linear-gradient(102.35deg, #2cc09e -66.24%, #95c9f4 114.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-footer {
  background: url("/images/footer-bg.png");
  background-position: top;
  @apply bg-fixed bg-no-repeat bg-cover;
}

.paywall {
  position: relative;
  overflow-x: clip;
  background: url("/images/paywall-bg.png");
  background-position: top;
  @apply p-4 bg-fixed bg-no-repeat bg-cover border border-accent-base rounded-2xl;
}

/* Pseudo element to add an opaque white background behind the bg image so it isn't transparent */
/* Apply box shadow to the pseudo element so that we can clip in the x direction */
.paywall:before {
  content: "";
  z-index: -1;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
  @apply absolute top-0 bottom-0 left-0 right-0 bg-white rounded-xl;
}

.slick-list {
  padding: 40px 0;
}

.slick-dots li button:before {
  font-size: 16px !important;
  color: #57ac91 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #57ac91;
}

.slick-dots {
  bottom: 0px;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  height: 100%;
}

.table-container th {
  @apply bg-white/25 text-fg-muted;
}

.table-container table th:nth-child(n + 2),
.table-container table td:nth-child(n + 2) {
  @apply border-l border-border-emphasis;
}

.table-container tbody tr {
  @apply border-t border-border-emphasis;
}

/* tippy style */
.tippy-box[data-theme~="primary"] {
  background-color: #085394;
  color: white;
}

.tippy-box[data-theme~="primary"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: #085394;
}

.tippy-box[data-theme~="primary"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #085394;
}

.tippy-box[data-theme~="primary"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: #085394;
}

.tippy-box[data-theme~="primary"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: #085394;
}

.tippy-box[data-theme~="toast"] {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}

.tippy-box[data-theme~="toast"] .tippy-content {
  padding: 0;
  background-color: transparent;
}

.tippy-box[data-theme~="light"] {
  border: 1px solid var(--border-base, #dfeaee);
  background: #fff;
  box-shadow: 8px 12px 26px 0px rgba(189, 201, 219, 0.26);
}

.tippy-box[data-theme~="dark"] {
  border: 0px;
  background: #3a5264;
  box-shadow: 8px 12px 26px 0px rgba(189, 201, 219, 0.26);
}

.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #dfeaee;
}

.tippy-box[data-theme~="light"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #dfeaee;
}

.tippy-box[data-theme~="light"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #dfeaee;
}

.tippy-box[data-theme~="light"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: #dfeaee;
}
.tippy-box[data-theme~="dark"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #3a5264;
}

.tippy-box[data-theme~="dark"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #3a5264;
}

.tippy-box[data-theme~="dark"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #3a5264;
}

.tippy-box[data-theme~="dark"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: #3a5264;
}

/* Toast stle */
.Toastify__toast-theme--light {
  @apply px-3 py-2 text-white rounded-lg;
}
.Toastify__toast-body {
  @apply p-0 sm-normal;
}
.Toastify__toast-theme--light.Toastify__toast--default {
  @apply bg-bgr-emphasis;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  @apply bg-green-emphasis;
}
.Toastify__toast-theme--light.Toastify__toast--error {
  @apply bg-red-emphasis;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border-left: 1px solid #068ef1;
  border-right: 1px solid #068ef1;
  border-bottom: 1px solid #068ef1;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 8px;
  z-index: 2;
  overflow: hidden;
  margin-top: -1px;
}

.react-autosuggest__container--open #search-input-box {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 12px 0px;
  margin: 0 0.5rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f2f7f9;
  border-radius: 8px;
}

.line-clamp-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.premium-bookmark-popup .tippy-arrow {
  left: -10px !important;
}

.paper-name {
  font-size: 1.125rem;
  /* 18px */
  font-family: var(--font-family);
  font-weight: var(--bold-font-weight);
}

/* gleap widget */
@media only screen and (max-width: 600px) {
  .bb-feedback-button-icon {
    width: 46px !important;
    height: 46px !important;
  }

  .bb-feedback-button .bb-logo-logo {
    width: 30px !important;
    height: 30px !important;
    top: 8px !important;
    left: 8px !important;
  }

  .bb-feedback-button .bb-logo-arrowdown {
    top: 16px !important;
    left: 14px !important;
  }

  .cl-card {
    max-width: calc(100vw - 3rem);
  }
}

@media only screen and (max-width: 640px) {
  .line-clamp-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 640px) {
  .share-btn-item {
    font-size: 0.9375rem;
    /* 15px */
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }

  .share-btn-item.hide {
    display: flex;
  }

  .paper-name {
    font-size: 1.375rem;
    /* 22px */
    font-family: var(--font-family);
    font-weight: var(--bold-font-weight);
  }
}

@media (min-width: 768px) {
  .research-paper-title {
    font-size: 1.375rem;
    /* 22px */
    font-family: var(--font-family);
    font-weight: var(--medium-font-weight);
  }
}

.pricing-card {
  @apply flex flex-col flex-wrap p-4 bg-white border rounded-xl;
}

.pricing-card.emphasized {
  @apply bg-[#F7FBFF] border-2 border-accent-base;
}

.pricing-card a {
  @apply underline;
}

/* sm: Two columns */
@media (min-width: 640px) {
  .pricing-card {
    @apply mt-3;
  }

  .pricing-card:nth-child(odd) {
    @apply border-r-0 rounded-r-none;
  }

  .pricing-card:nth-child(even) {
    @apply rounded-l-none;
  }

  .pricing-card.emphasized {
    @apply mt-0 rounded-bl-none pt-7 rounded-xl;
  }
}

/* xl: Four columns */
@media (min-width: 1439px) {
  .pricing-card {
    @apply max-w-[300px];
  }

  .pricing-card.emphasized {
    @apply border-b rounded-b-none border-b-border-base pt-7;
  }

  .pricing-card:nth-child(3) {
    @apply border-l-0 rounded-none;
  }
}

/* Clerk restyling */
.cl-footer a {
  @apply text-fg-muted !important;
}

/* Clerk CSS Hacks: Added on 3/22/24 as a workaround to disable unwanted functionality since the prebuilt components don't give us the ability to customize them in the way we need. */

/* Hide the "Billing Admin" option from all "role" dropdowns within the prebuilt clerk components on the Organizations page. 
Note: This assumes that "Billing Admin" continues to be the first option in the dropdown, so that we can select it using css "first-child". It does not match on the name "Billing Admin" itself.  */
.cl-organizationProfile-root
  .cl-selectOptionsContainer__role
  > div
  > div:first-child {
  display: none;
}

/* Hide the "Manage Organization" and "Create Organization" buttons from the organization switcher on the organizations page */
.cl-organizationSwitcherPopoverActionButton.cl-organizationSwitcherPopoverActionButton__manageOrganization,
.cl-organizationSwitcherPopoverActionButton.cl-organizationSwitcherPopoverActionButton__createOrganization {
  display: none;
}

.cl-userProfile-root {
  width: auto !important;
}

/* Markdown Styles */
.markdown-p {
  @apply mt-0 mb-3;
}

ul .markdown-p,
ol .markdown-p,
li .markdown-p {
  @apply mb-2;
}

.markdown-ol {
  /* The width of the ol number marker is based on the fontSize of the list item.
  if the padding is less than the marker width, the number will extend outside of the
  parent component. For the current a font-size of 1rem (16px) the number width is 27.31px.
  Setting the padding to 29px to provide some space between the number and the component bounds. */
  padding-left: 29px;
}

/* Use a custom bullet point for ul to allow for more control of size. */
.markdown-ul {
  @apply list-none;
}

.markdown-ul li {
  @apply relative pl-4;
}

.markdown-ul li::before {
  content: "•";
  @apply absolute top-0 left-0 text-fg-faint tiny-normal;
}

li .markdown-ol,
li .markdown-ul {
  @apply pl-0;
}

/* To be used to increase the weight of a fontello icon. */
.bold-icon::before {
  /* 
    Since we cannot apply a bold font directly to a fontello glyph we can update font-smoothing
    to use subpixel-antialiased to make the icons appear bolder. 
  */
  -webkit-font-smoothing: subpixel-antialiased !important;
}
